<template>
  <div class="mb-4 flex flex-col">
    <div class="overflow-x-auto flex">
      <StoryItem
        v-for="worship in worships"
        :key="worship.sequence"
        :worship="worship"
      />
    </div>
    <Popover class="relative self-end" v-if="showInfo">
      <PopoverButton>
        <div class="flex items-center space-x-2 mt-2 cursor-pointer">
          <div class="text-lg text-blue-500">Info Kebaktian</div>
          <img class="w-6 h-6" src="~@/assets/icons/info-blue.svg" alt="information">
        </div>
      </PopoverButton>
      <PopoverPanel class="absolute z-10 bg-gray-600 rounded-lg p-4 w-60 right-4 top-10 bg-gradient-to-r from-blue-500 to-blue-400 text-white">
        <div class="text-lg">Kebaktian 1 - 06.00</div>
        <div class="text-lg">Kebaktian 2 - 08.00</div>
        <div class="text-lg">Kebaktian 3 - 10.30</div>
        <div class="text-lg">Kebaktian 4 - 17.00</div>
      </PopoverPanel>
    </Popover>
  </div>
</template>

<script>
import StoryItem from './StoryItem.vue'
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'Story',
  components: {
    StoryItem,
    Popover,
    PopoverButton,
    PopoverPanel
  },
  created () {
    this.initComponent()
  },
  computed: {
    ...mapState('worship', ['worships']),
    showInfo () {
      return this.worships.some(worship => worship.youtubeVideoId)
    }
  },
  methods: {
    ...mapActions('worship', ['fetchWorships']),
    initComponent () {
      this.fetchWorships()
    }
  }
}
</script>

<style scoped>

</style>
